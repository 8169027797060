<template>
  <VContainer
    fluid
  >
    <DialogWrapper
      v-model="showSearchBar"
      width="100%"
      transparent
      content-class="ma-0 elevation-0 align-self-start mt-3 search-dialog"
    >
      <div
        class="search-dialog__content"
      >
        <VContainer class="pl-4">
          <VRow>
            <VCol
              cols="12"
              md="10"
              :offset="$vuetify.breakpoint.mdAndDown? 0 :1"
            >
              <VContainer
                fluid
              >
                <VRow>
                  <VCol cols="12">
                    <SearchBar
                      v-if="showSearchBar"
                      to-search-page
                    />
                  </VCol>
                </VRow>
              </VContainer>
            </VCol>
          </VRow>
        </VContainer>
      </div>
    </DialogWrapper>
    <DialogWrapper
      v-model="showTaskForm"
      fullscreen
      @click:outside="closeModal('showTaskForm')"
    >
      <TaskForm
        v-if="showTaskForm"
        :edit="edit"
        :edit-entity="activeEntity"
        :level="level"
        :location="location"
        @close="closeModal('showTaskForm')"
        @save="handleSave"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="showTaskMove"
      width="536"
      content-class="overflow-visible"
      scrollable
      @click:outside="closeModal('showTaskMove')"
    >
      <ActionTaskForm
        v-if="showTaskMove"
        :id="taskToMoveId"
        :levels="levelsList"
        :form-action-type="formActionType"
        @close="closeModal('showTaskMove')"
        @copy="id => handleMove(id)"
      />
    </DialogWrapper>

    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          Удалить задачу?
        </h3>
        <p class="mb-0">
          Контент {{ toDeleteTaskName }}
          будет удален без возможности восстановления.
        </p>
      </DeleteForm>
    </DialogWrapper>

    <DialogWrapper
      v-model="deleteInfoDialog"
      width="396"
      hide-overlay
      @click:outside="deleteInfoDialog = false"
    >
      <VRow>
        <VCol class="pt-0 pb-2">
          <h2>
            Для удаления задачи<br>необходимо убрать упоминания
          </h2>
        </VCol>
      </VRow>
      <VRow v-if="deleteInfo.mailing">
        <VCol class="py-0">
          <p class="mb-0">
            Задача упоминается в событиях:
          </p>
        </VCol>
      </VRow>
      <VRow>
        <VCol class="pb-0">
          <VDivider />
        </VCol>
      </VRow>
      <VRow>
        <VCol
          v-if="deleteInfo.mailing"
          cosl="12"
          class="py-0"
        >
          <VList
            class="pa-0 overflow-auto"
            max-height="350px"
          >
            <VListItem
              v-for="item in deleteInfo.mailing"
              :key="item.id"
              dense
              class="pa-3 striped-list"
              :to="`/event#location=${location.id}&mailing=${item.id}`"
            >
              <VListItemContent class="ma-0 pa-0">
                {{ item.name }}
              </VListItemContent>
              <VListItemIcon class="mx-3 pa-0 mr-0">
                <VIcon
                  size="19"
                  color="tt-black"
                >
                  fal fa-arrow-to-right
                </VIcon>
              </VListItemIcon>
            </VListItem>
          </VList>
        </VCol>
        <TTBtn
          absolute
          top
          right
          width="20"
          height="20"
          min-width="20"
          class="pa-0"
          depressed
          color="transparent"
          @click="deleteInfoDialog = false"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
      </VRow>
    </DialogWrapper>

    <VRow>
      <VCol>
        <div class="breadcrumbs">
          <TTBreadcrumbs :items="breadcrumbs" />
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <h1
          class="tt-text-headline-1"
          data-test="task-list-title"
        >
          {{ level.name }}
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          ref="sortableTable"
          hide-default-footer
          :items="tasks"
          class="clickable"
          :headers="headers"
          :items-per-page="tasks.length"
          data-test="tasks-table"
        >
          <template #item="{item, index}">
            <tr
              :key="itemKey(item)"
              class="sortableRow cursor--pointer"
              :data-test="`task-list-row${itemKey(item)}`"
              @click="() => $router.push(`/content/${item.id}`)"
            >
              <td class="sortHandle">
                <VIcon size="19">
                  fal fa-grip-lines
                </VIcon>
              </td>
              <td data-test="task-list-number">
                {{ index + 1 }}
              </td>
              <td>
                <div class="d-flex py-4">
                  <div
                    class="mr-5 pt-1 text-center"
                    style="min-width: 25px"
                  >
                    <VIcon
                      size="19"
                      color="tt-black"
                      data-test="task-list-icon-type"
                    >
                      {{ taskIconMap[item.type] }}
                    </VIcon>
                  </div>
                  <div>
                    <p
                      data-test="task-name"
                      class="mb-0"
                    >
                      {{ item.name }}
                    </p>
                    <p
                      data-test="task-list-counters"
                      class="tt-black--text text--lighten-2 mb-0"
                    >
                      {{ printCounters(contentCounters[item.id]) }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <span data-test="task-list-time">
                    {{ Math.floor(item.duration_min / 60) > 0 ? Math.floor(item.duration_min / 60) + ' ч ' : '' }}
                    {{ item.duration_min % 60 }}  мин
                  </span>
                  <span
                    v-if="item.is_required"
                    class="pl-2"
                    data-test="task-list-circle"
                  >
                    <VIcon
                      color="error"
                      size="16"
                    >
                      fas fa-dot-circle
                    </VIcon>
                  </span>
                </div>
              </td>
              <td>
                <div class="d-flex justify-space-between align-start">
                  <p
                    class="mr-10 mb-0"
                    data-test="task-list-bonus"
                  >
                    {{ contentCounters[item.id].bonus || 0 }}
                  </p>
                  <VMenu
                    content-class="v-menu-shadow"
                    offset-y
                    left
                    min-width="250px"
                    max-width="250px"
                    nudge-right="16px"
                    data-test="task-list-menu"
                  >
                    <template #activator="{ on }">
                      <TTBtn
                        class="table-menu-button"
                        fab
                        small
                        depressed
                        color="transparent tt-ghost--text"
                        :ripple="false"
                        data-test="task-list-help-menu"
                        v-on="on"
                      >
                        <VIcon size="19">
                          fal fa-ellipsis-h
                        </VIcon>
                      </TTBtn>
                    </template>
                    <VCard class="v-menu-card">
                      <VList dense>
                        <VListItem
                          class="custom-menu-item"
                          :data-test="`button-copy-${item.id}`"
                          @click="onClickCopy(item)"
                        >
                          <VListItemIcon class="mr-4 ml-0">
                            <VIcon
                              size="19"
                              color="tt-black"
                            >
                              fal fa-copy
                            </VIcon>
                          </VListItemIcon>
                          <VListItemContent>Скопировать</VListItemContent>
                        </VListItem>
                        <VListItem
                          class="custom-menu-item"
                          :data-test="`button-move-${item.id}`"
                          @click="onClickTransfer(item)"
                        >
                          <VListItemIcon class="mr-4 ml-0">
                            <VIcon
                              size="19"
                              color="tt-black"
                            >
                              fal fa-arrow-to-right
                            </VIcon>
                          </VListItemIcon>
                          <VListItemContent>Перенести в другой уровень</VListItemContent>
                        </VListItem>
                        <VListItem
                          class="custom-menu-item"
                          :data-test="`button-edit-${item.id}`"
                          @click="toggleEdit('showTaskForm', item)"
                        >
                          <VListItemIcon class="mr-4 ml-0">
                            <VIcon
                              size="19"
                              color="tt-black"
                            >
                              fal fa-pencil
                            </VIcon>
                          </VListItemIcon>
                          <VListItemContent>Редактировать</VListItemContent>
                        </VListItem>
                        <VListItem
                          class="custom-menu-item"
                          :data-test="`button-delete-${item.id}`"
                          @click="openDelete(item)"
                        >
                          <VListItemIcon class="mr-4 ml-0">
                            <VIcon
                              size="19"
                              color="error"
                            >
                              fal fa-trash-alt
                            </VIcon>
                          </VListItemIcon>
                          <VListItemContent class="error--text">
                            Удалить
                          </VListItemContent>
                        </VListItem>
                      </VList>
                    </VCard>
                  </VMenu>
                </div>
              </td>
            </tr>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import Sortable from 'sortablejs';
import omit from 'lodash/omit';
import * as snamiApi from '@/services/backend/snami';
import { taskIconMap } from '@/constants';
import TaskForm from '@/components/forms/TaskForm.vue';
import ActionTaskForm from '@/components/forms/ActionTaskForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import SearchBar from '@/components/shared/SearchBar.vue';
import { R_CONTENT_SEARCH, R_TASK_LEVEL } from '@/plugins/vue-router';

export default {
  name: 'TaskList',
  components: {
    SearchBar,
    DialogWrapper,
    TaskForm,
    ActionTaskForm,
    DeleteForm,
  },
  data() {
    return {
      headers: [
        {
          text: '',
          value: 'drag',
          sortable: false,
        },
        {
          text: '№',
          value: 'n',
          sortable: false,
        },
        {
          text: 'Название',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Выполнение',
          value: 'duration',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Бонусы',
          value: 'bonus',
          sortable: false,
        },
      ],
      showTaskForm: false,
      showTaskMove: false,
      formActionType: 'move',
      deleteDialog: false,
      toDeleteTaskName: '',
      toDeleteId: 0,
      deleteInfo: {},
      deleteInfoDialog: false,
      edit: false,
      activeEntity: {},
      taskToMoveId: null,
      location: {},
      level: {},
      taskIconMap,
      tasks: [],
      levelsList: [],
      tasksContentList: [],
      // Sortable
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      showSearchBar: false,
    };
  },
  computed: {
    levelId() {
      return this.$route.params.id;
    },
    contentCounters() {
      const contents = {};
      this.tasks.forEach((t) => {
        contents[t.id] = { bonus: 0 };
      });
      this.tasksContentList.forEach((tc) => {
        contents[tc.task_id].bonus += tc.bonus;
        if (contents[tc.task_id][tc.type]) {
          contents[tc.task_id][tc.type] += 1;
        } else {
          contents[tc.task_id][tc.type] = 1;
        }
      });
      return contents;
    },
    breadcrumbs() {
      if (!this.level?.name || !this.location?.name) return [];
      return [
        {
          to: '/level/',
          text: this.location.is_active ? this.location.name : `Тестовая • ${this.location.name}`,
          'data-test': 'task-list-breadcrumbs-link0',
        },
        {
          text: this.level.for_tutor ? `${this.level.name} (Наставничество)` : this.level.name,
          disabled: true,
          'data-test': 'task-list-breadcrumbs-link1',
        }];
    },
  },
  watch: {
    levelId() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    Sortable.create(
      this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
      {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        onEnd: this.dragReorder,
      },
    );
    this.$root.$on('showTaskForm', this.createTask);
    this.$root.$on('onClickShowSearchBar', this.toggleSearchBar);
  },
  beforeDestroy() {
    this.$root.$off('showTaskForm', this.createTask);
    this.$root.$on('onClickShowSearchBar', this.toggleSearchBar);
  },
  methods: {
    handleChange(value) {
      this.$router.push({
        name: R_CONTENT_SEARCH,
        query: { search: value },
      });
    },
    createTask() {
      this.openModal('showTaskForm');
    },
    toggleSearchBar() {
      this.showSearchBar = !this.showSearchBar;
    },
    dragReorder({
      oldIndex,
      newIndex,
    }) {
      const movedItem = this.tasks.splice(oldIndex, 1)[0];
      this.tasks.splice(newIndex, 0, movedItem);

      const data = {
        level_id: this.level.id,
        task_ids: this.tasks.map((item) => item.id),
      };
      this.$repositories.task.reorder({ data });
    },
    itemKey(item) {
      if (!this.itemKeys.has(item)) {
        this.currentItemKey += 1;
        this.itemKeys.set(item, this.currentItemKey);
      }
      return this.itemKeys.get(item);
    },
    getData() {
      this.tasksContentList = [];
      this.tasks = [];
      snamiApi.getLevel({ filter: { id: this.levelId } })
        .then((r) => {
          const [data] = r.data.data;
          this.level = data;
        })
        .then(() => this.$repositories.location.list({ filter: { id: this.level.location_id } }))
        .then((r) => {
          const [data] = r.data.data;
          this.location = data;
        })
        .then(() => snamiApi.getLevel({ filter: { location_id: this.location.id } }))
        .then((r) => {
          const { data } = r.data;
          this.levelsList = data.map((item) => ({
            text: `${item.n}: ${item.name}`,
            value: item.id,
            number: item.n,
            disabled: item.id === this.level.id,
          }))
            .filter((item) => item.number !== 0);
        })
        .catch((e) => console.warn(e));
      this.getTaskList();
    },
    getTaskList() {
      this.tasksContentList = [];
      this.tasks = [];
      this.$repositories.task.list({ data: { filter: { level_id: [this.levelId] } } })
        .then((r) => {
          const { data } = r.data;
          this.tasks = data;
        })
        .then(() => this.$repositories.taskContent.list({ data: { filter: { task_id: this.tasks.map((i) => i.id) } } })
          .then((r) => {
            const { data } = r.data;
            this.tasksContentList = data;
          }))
        .catch((e) => console.warn(e));
    },
    printCounters(obj) {
      const arr = [];
      const nObj = omit(obj, ['name', 'bonus']);
      Object.keys(nObj)
        .forEach((k) => {
          arr.push(`${k}: ${obj[k]}`);
        });
      return arr.join(', ');
    },

    toggleEdit(name, item) {
      this.edit = true;
      this.activeEntity = item;
      this[name] = true;
    },
    onClickTransfer(item) {
      this.taskToMoveId = item.id;
      this.openModal('showTaskMove', item);
      this.formActionType = 'move';
    },
    onClickCopy(item) {
      this.taskToMoveId = item.id;
      this.openModal('showTaskMove', item);
      this.formActionType = 'copy';
    },
    handleSave() {
      this.closeModal('showTaskForm');
      this.getTaskList();
    },
    handleMove(id) {
      this.$router.push({ name: R_TASK_LEVEL, params: { id } });
      this.closeModal('showTaskMove');
    },
    closeModal(modalName) {
      this[modalName] = false;
      this.activeEntity = {};
      this.taskToMoveId = null;
      this.formActionType = 'move';
      this.edit = false;
    },
    openModal(name, item) {
      this[name] = true;
      if (item) {
        this.activeEntity = item;
        this.edit = true;
      }
    },
    openDelete(item) {
      snamiApi.getContentConnections({
        entity: 'TASK',
        id: item.id,
      })
        .then(({ data }) => {
          if (data.data.has_connection) {
            this.deleteInfo = data.data;
            this.deleteInfoDialog = true;
          } else {
            this.deleteDialog = true;
            this.toDeleteId = item.id;
            this.toDeleteTaskName = item.name;
          }
        });
    },
    handleDelete() {
      this.$repositories.task.delete({ data: { id: this.toDeleteId } })
        .then(() => {
          this.deleteDialog = false;
          this.toDeleteId = 0;
          this.getTaskList();
        })
        .catch((e) => console.warn(e));
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
