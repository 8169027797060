<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol
        cols="1"
      />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <h1
              data-test="task-form-title"
              class="mb-6 tt-text-headline-1"
            >
              {{ edit ? 'Редактирование' : 'Добавление новой' }} задачи
            </h1>
            <VRow>
              <VCol>
                <TTTextField
                  large
                  label="Название"
                  placeholder="Введите название"
                  data-test="task-form-input-name"
                  :value="entity.name"
                  :error="validation('name').status"
                  :error-messages="validation('name').value"
                  @input="v => handleChange('name',v)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol
                cols="12"
                class="pb-0"
              >
                <div class="d-flex align-center mb-3">
                  <h4 class="tt-text-body-2">
                    Выберите иконку
                  </h4>
                  <VMenu
                    v-model="contestHelpMenu"
                    content-class="elevation-0"
                    nudge-top="40px"
                    right
                    offset-x
                    :close-on-content-click="false"
                    max-width="360"
                  >
                    <template #activator="{ on, attrs }">
                      <VIcon
                        size="19"
                        class="ml-1 hint-icon"
                        v-bind="attrs"
                        color="primary"
                        data-test="task-form-help-menu"
                        v-on="on"
                      >
                        fal fa-question-circle
                      </VIcon>
                    </template>
                    <VRow class="pl-0 pa-5 ma-0">
                      <VCol
                        cols="1"
                        class="pa-0 ma-0"
                      >
                        <VCard
                          class="hint-with-triangle"
                          elevation="0"
                        />
                      </VCol>
                      <VCol
                        cols="11"
                        class="pa-0 ma-0"
                        elevation="0"
                      >
                        <VCard
                          class="contest_help"
                          flat
                        >
                          <div>
                            Иконка отображается в списке задач в приложении и веб-версии.
                          </div>
                        </VCard>
                      </VCol>
                    </VRow>
                  </VMenu>
                </div>
              </VCol>
              <VCol class="pt-0">
                <VBtnToggle
                  data-test="task-form-task-type-icons-buttons"
                  class="button-toggle"
                  borderless
                  :value="entity.type"
                  @change="v => handleChange('type',v)"
                >
                  <TTTooltip
                    v-for="button in taskIconList"
                    :key="button.type"
                    bottom
                  >
                    <template #activator="{ on, attrs}">
                      <VBtn
                        class="button-toggle__buttons-border"
                        :value="button.type"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <VIcon size="19">
                          {{ button.icon }}
                        </VIcon>
                      </VBtn>
                    </template>
                    <span>{{ button.prompt }}</span>
                  </TTTooltip>
                </VBtnToggle>
                <div
                  v-if="validation('type').status"
                  class="v-messages error--text mt-2"
                  role="alert"
                >
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message tt-text-body-2">
                      Необходимо выбрать иконку
                    </div>
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="4">
                <VCheckbox
                  v-model="entity.is_required"
                  data-test="task-form-required-checkbox"
                  hide-details="auto"
                  class="py-0 my-0"
                  color="tt-black"
                  :ripple="false"
                  label="Блокирующая"
                  :error="validation('is_required').status"
                  :error-messages="validation('is_required').value"
                  @change="v => handleChange('is_required',v)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="4">
                <TTTextField
                  v-mask="'##:##'"
                  large
                  label="Время выполнения"
                  placeholder="Введите время в формате ЧЧ:ММ"
                  data-test="task-form-duration-time-input"
                  :value="durationTime"
                  :error="validation('durationTime').status"
                  :error-messages="validation('durationTime').value"
                  @input="v => handleChange('durationTime', v)"
                />
              </VCol>
            </VRow>
            <VRow class="pt-3">
              <VCol>
                <TTBtn
                  large
                  color="tt-secondary"
                  data-test="task-form-cancel-button"
                  depressed
                  class="mr-6"
                  @click="handleClose"
                >
                  Отменить
                </TTBtn>
                <TTBtn
                  data-test="task-form-save-button"
                  depressed
                  large
                  :loading="saving"
                  :disabled="saving"
                  @click="handleSave"
                >
                  {{ edit ? 'Сохранить' : 'Добавить' }}
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test="task-form-close-button"
              @click="() => handleClose()"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mask } from 'vue-the-mask';
import { validation, handleError, watchValidationStatus } from '@/services';
import { dictionaryKeys, taskIconMap, taskTypeTranslate } from '@/constants';

export default {
  name: 'TaskForm',
  directives: { mask },
  props: {
    level: {
      type: Object,
      default: () => ({}),
    },
    location: {
      type: Object,
      default: () => ({}),
    },
    editEntity: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      contestHelpMenu: false,
      validationStatus: [],
      taskIconList: [],
      saving: false,
      entity: {
        name: '',
        duration_hour: 0,
        duration_min: 0,
        is_required: false,
        type: null,
        id: null,
        description: '',
      },
    };
  },
  computed: {
    durationTime: {
      get() {
        let hours = Math.floor(this.editEntity.duration_min / 60);
        let mins = this.editEntity.duration_min % 60;
        if (hours < 10) {
          hours = `0${hours}`;
        }
        if (mins < 10) {
          mins = `0${mins}`;
        }
        return `${hours}:${mins}`;
      },
      set(time) {
        const [hour, min] = time.split(':');
        this.entity.duration_hour = hour ? parseInt(hour, 10) : 0;
        this.entity.duration_min = min ? parseInt(min, 10) : 0;
      },
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (this.edit) {
      this.entity = { ...this.editEntity };
    }
    this.$repositories.customer.getCustomerDictionary({
      data: { key: [dictionaryKeys.levelTaskType] },
    }).then((r) => {
      const { data } = r.data;
      data.level_task_type.forEach((item) => this.taskIconList
        .push({
          type: item,
          icon: taskIconMap[item] || taskIconMap.NONE,
          prompt: taskTypeTranslate[item] || taskTypeTranslate.NONE,
        }));
    }).catch((e) => console.warn(e));
  },
  methods: {
    validation,
    handleError,
    handleChange(name, value, target) {
      if (name === 'is_required') {
        if (value) {
          this.entity[name] = value;
        } else {
          /** Устанавливаем false вручную, потому что чекбокс при снятии возвращает не false, а null */
          this.entity[name] = false;
        }
      } else if (name === 'durationTime') {
        this[name] = value;
      } else {
        this.entity[name] = value;
      }

      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    async handleSave() {
      try {
        this.saving = true;
        const {
          // eslint-disable-next-line camelcase
          id, name, is_required, type, description, duration_hour: hours = 0, duration_min: mins = 0,
        } = this.entity;
        const durationHour = hours + Math.floor(this.entity.duration_min / 60);
        const durationMin = mins % 60;
        let data;
        if (this.edit) {
          data = {
            duration_hour: durationHour,
            duration_min: durationMin,
            id,
            is_required,
            name,
            type,
            level_id: this.level.id,
            description,
            location_id: this.location.id,
          };
          await this.$repositories.task.update({ data });
        } else {
          data = {
            duration_hour: durationHour,
            duration_min: durationMin,
            name,
            type,
            is_required,
            level_id: this.level.id,
          };
          await this.$repositories.task.create({ data });
        }
        this.$emit('save');
      } catch (e) {
        let timeErrorCount = 0;
        this.handleError(e);
        this.validationStatus.forEach((item) => {
          if (item.key === 'duration_min' || item.key === 'duration_hour') {
            timeErrorCount += 1;
          }
        });
        if (timeErrorCount) {
          this.validationStatus.push({ value: 'Необходимо указать время выполнения задачи', key: 'durationTime' });
        }
      } finally {
        this.saving = false;
      }
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.button-toggle{
  border: 1px solid #EBECED;

  .button-toggle__buttons-border{
    position: relative;

    &.v-btn {
      background-color: #eee !important;

      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 24px;
        background: #EBECED;
        width: 1px;
        top: 10px;
        left: 0;
        box-shadow: none;
      }
      &:hover{
        &::before{
          display: block;
        }
      }
    }

    &.v-btn--active{
      background-color: #cfcfcf !important;

      &:hover{
        &::before{
          display: block;
          background: #EBECED;
        }
      }
    }
  }
}
.contest_help{
  width: 340px;
  padding: 16px;
  border: solid 1px #ddd;
  box-shadow: 0px 2px 24px rgba(11, 18, 24, 0.08) !important;
}
.substitution-button{
  max-height: 36px !important;
}
::v-deep .v-label {
  font-size: 14px;
}
</style>
