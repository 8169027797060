<template>
  <VContainer class="pa-0">
    <VRow dense>
      <VCol>
        <h3>{{ titleSlug }} задачи</h3>
      </VCol>
    </VRow>
    <VRow dense>
      <VCol>
        <div v-if="isCopyFormTypeAction">
          Выберите уровень, куда будет скопирована задача. Можно выбрать только те уровни, в которых допустимо
          добавление такого типа задач. Она будет размещена последней в списке.
        </div>
        <div v-else>
          Выберите уровень, куда хотите перенести задачу.
          Она будет отображена последней в списке задач выбранного уровня.
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="py-1">
        <TTSelect
          large
          label="Уровень"
          placeholder="Выберите уровень"
          :value="activeLevel"
          :items="levels"
          :menu-props="{maxWidth : '496px', offsetY : true, bottom : true}"
          data-test="move-task-form-select-locations"
          :error="!!globalErrorMessage"
          @change="handleLevel"
        />
        <div
          v-if="globalErrorMessage"
          class="error--text mt-2"
        >
          Для {{ errorSlug }} необходимо выбрать уровень.
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="d-flex justify-end">
        <TTBtn
          large
          color="tt-secondary"
          depressed
          class="mr-3"
          data-test="move-task-form-button-cancel"
          @click="() => {
            handleClose();
          }"
        >
          Отменить
        </TTBtn>
        <TTBtn
          large
          depressed
          :loading="loading"
          data-test="move-task-form-button-save"
          @click="onSubmit"
        >
          {{ buttonTextSubmit }}
        </TTBtn>
      </VCol>
    </VRow>
    <TTBtn
      absolute
      top
      right
      data-test="move-task-form-close-button"
      width="20"
      height="20"
      min-width="20"
      depressed
      color="transparent"
      @click="() => {
        handleClose();
      }"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-times
      </VIcon>
    </TTBtn>
  </VContainer>
</template>

<script>
import { handleError, validation, watchValidationStatus } from '@/services';

export default {
  name: 'ActionTaskForm',
  props: {
    levels: {
      type: Array,
      default: () => [],
    },
    formActionType: {
      type: String,
      default: () => 'copy',
      // TODO  Вынести в константы?
      validator: (value) => ['copy', 'move'].indexOf(value) !== -1,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      activeLevel: '',
      globalErrorMessage: '',
    };
  },
  computed: {
    isCopyFormTypeAction() {
      return this.formActionType === 'copy';
    },
    titleSlug() {
      return this.isCopyFormTypeAction ? 'Копирование' : 'Перенос';
    },
    descriptionSlug() {
      return this.isCopyFormTypeAction ? 'скопировать' : 'перенести';
    },
    buttonTextSubmit() {
      return this.isCopyFormTypeAction ? 'Копировать' : 'Перенести';
    },
    errorSlug() {
      return this.isCopyFormTypeAction ? 'копирования' : 'переноса';
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  methods: {
    handleError,
    validation,
    handleLevel(val) {
      this.activeLevel = val;
      this.globalErrorMessage = '';
    },
    createPayload() {
      const data = {};
      if (this.isCopyFormTypeAction) {
        data.target_level = this.activeLevel;
        data.source_task_id = this.id;
      } else {
        data.level_id = this.activeLevel;
        data.id = this.id;
      }
      return data;
    },
    async onSubmit() {
      try {
        this.loading = true;
        const payload = this.createPayload();
        if (this.isCopyFormTypeAction) {
          await this.$repositories.task.copy({ data: payload });
        } else {
          await this.$repositories.task.move({ data: payload });
        }
        this.$emit('copy', this.activeLevel);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.loading = false;
      }
    },
    handleClose(e) {
      this.activeLevel = null;
      this.$emit(e || 'close');
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
